import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import figureFormatter from "@/services/utils/figureFormatter";
import { useStore } from "vuex";
import useDate from '@/services/utils/day';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const pdfPrinter = () => {
    const { formatDate } = useDate();
    const store = useStore();
    const { commaFormat } = figureFormatter();
    const userName = store.state.user.user.name;

    const exportToPDF = (company, summeryObj) => {

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 100, 100, 60, 90 ],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(summeryObj),
            styles : {
                header: {
                    fontSize: 24
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: 'Daily Sales-Collection Summary'
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (summeryObj) => {
        return [
            {
                text: 'Daily Sales-Collection Summary',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },
            {
                style: 'tableExample',
                margin: [30, 0, 50, 0],
                table: {
                    widths: ['*', '*'],
                    body: [
                        [{ text: 'Particulars', alignment: 'left', bold: true }, { text: 'Amount', alignment: 'right', bold: true }],
                        ... setGroupData(summeryObj.groupedData),
                        [ { text: '', alignment: 'left', bold: false, colSpan: 2 }, { text: 0, alignment: 'right'}],
                        [ { text: 'Gross Income:', alignment: 'left', bold: true }, {text:  summeryObj.grossIncome, alignment: 'right' } ],
                        [ { text: 'Receivable Adjustment', alignment: 'left', bold: true }, {text:  '', alignment: 'right' } ],
                        [ { text: '(Add) Advance Money Received:', alignment: 'left', bold: false }, { text: summeryObj.advMoneyReceived, alignment: 'right'}],
                        [ { text: '(Less) Advance Adjustment:', alignment: 'left', bold: false }, { text: summeryObj.advAdjustment, alignment: 'right'}],
                        [ { text: '(Add) Previous Bill Adjustment:', alignment: 'left', bold: false }, { text: summeryObj.prevBillAdjustment, alignment: 'right'}],
                        [ { text: '(Less) Today\'s Due:', alignment: 'left', bold: false }, { text: summeryObj.todayDue, alignment: 'right'}],
                        [ { text: '(Less) Net Discount:', alignment: 'left', bold: false }, { text: summeryObj.netDiscount, alignment: 'right'}],
                        [ { text: 'Total Receivable Adjustment:', alignment: 'left', bold: true }, {text: summeryObj.receivableAdjustment, alignment: 'right' } ],
                        [ { text: 'Net Collection:', alignment: 'left', bold: true }, {text: summeryObj.netCollection, alignment: 'right' } ],
                        [ { text: '', alignment: 'left', bold: false, colSpan: 2 }, { text: 0, alignment: 'right'}],
                        [ { text: 'Received Amount:', alignment: 'left', bold: false }, { text: summeryObj.receivedAmount, alignment: 'right'}],
                        [ { text: 'Refund Amount:', alignment: 'left', bold: false }, { text: summeryObj.refundAmount, alignment: 'right'}],
                        [ { text: 'Check:', alignment: 'left', bold: false }, { text: summeryObj.check, alignment: 'right'}],
                    ]
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    }
                }
            },
        ]
    }

    const setGroupData = (groupData) => {
        let formattedData = [];

        for(const key in groupData){
            const data = [
                [ { text: key, alignment: 'left', bold: true, colSpan: 2 }, { text: 0, alignment: 'right'}],
                ...groupData[key].map(item => {
                    return [ { text: item.name, alignment: 'left', bold: false }, { text: item.amount, alignment: 'right'}]
                }),
                [ { text: `Total ${key}`, alignment: 'left', bold: true }, { text: commaFormat(sumTotalParentGroupAmount(groupData[key])), alignment: 'right'}]
            ];
            formattedData = formattedData.concat(data);
        }

        return formattedData;
    }

    const sumTotalParentGroupAmount = groupData => groupData.reduce((total, child) => total + child.amount, 0);

    const getHeader = (company) => {
        return {
            margin: [ 30, 25, 30, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60
                },
                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }

    const getFooter = (userName) =>{
        return {
            margin: [ 25, 0 ],
            columns: [
                {
                    columns: [
                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },
                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;
