<template>
  <div class="card">

    <div class="bg-blue-light p-1">
      <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="Product category wise sales report"
      />
    </div>

    <div class="my-2 px-2">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label
              for="colFormLabel"
              class="col-form-label"
          >
            Date
          </label>
          <input
              @change="updateUrl"
              v-model="date"
              type="date"
              class="form-control date-mask"
              placeholder="From"
          >
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label
            for="colFormLabel"
            class="col-form-label"
          >
            Invoice Type
          </label>
          <v-select
            multiple
            placeholder="Select Bill Type"
            v-model="billType"
            :options="billTypeList"
            label="label"
            :reduce="label => label.value"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label
            for="colFormLabel"
            class="col-form-label"
          >
            Created By:
          </label>
          <v-select
            placeholder="Created By"
            :options="contactList"
            label="name"
            :reduce="(name) => name.id" v-model="created_by" />
        </div>

        <div class="col-12 col-lg-3 col-md-4 col-sm-6 d-flex justify-content-between mt-auto">
          <button
              style="min-width: 64px;"
              @click="getReport"
              class="btn btn-primary btn-block waves-effect waves-float waves-light"
          >
              Go
          </button>
          <button
              @click="exportReport"
              class="btn btn-outline-secondary btn-sm mb-2"
          >Export
          </button>
        </div>
        </div>
      </div>

    <div class="col-12 px-2">
      <ListTable
          :lists="lists"
          :groupNames="groupNames"
          :finalTotal="finalTotal"
          :saleReturns="saleReturns"
          :saleReturnPreviousBills="saleReturnPreviousBills"
          :ipdSettlementReceipts="ipdSettlementReceipts"
          :previousBillReceipts="previousBillReceipts"
          :advanceReceipts="advanceReceipts"
      />
    </div>
    <div class="mb-2"></div>
    <Loader v-if="loading"/>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import {useRoute, useRouter} from 'vue-router';
import ListTable from '@/components/molecule/company/hospital/hospital-admin/SalesReportProductwiseTable.vue';
import handleHospitalReport from "@/services/modules/hospital/report";
import {computed, inject, onMounted, ref} from "vue";
import Loader from "@/components/atom/LoaderComponent.vue";
import {useStore} from "vuex";
import handleInventory from "@/services/modules/inventory";
import handleImportTracker from "@/services/modules/procurement/importTracker";

const {fetchSalesReportProductWise} = handleHospitalReport()
const {fetchHome} = handleInventory()
const { fetchContacts } = handleImportTracker();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const store = useStore()
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const business = ref([])
const lists = ref([])
const groupNames = ref({})
const finalTotal = ref({})
const saleReturns = ref([])
const saleReturnPreviousBills = ref([])
const ipdSettlementReceipts = ref([])
const previousBillReceipts = ref([])
const advanceReceipts = ref([])
const date = ref()
const token = localStorage.getItem('token');
const billTypeList = ref([])
const billType = ref(null)
const contactList = ref([])
const created_by = ref(null)

//computed
const companyId = computed(() => route.params.companyId);
const dateValue = computed(() => route.query.date);

//methods
const getQuery = () => {
  let query = '?company_id=' + companyId.value
  if(billType.value && billType.value.length > 0) query += `&sale_type=${billType.value}`
  if (created_by.value) query += `&created_by=${created_by.value}`
  if (dateValue.value) query += '&date=' + dateValue.value
  return query
};
const updateUrl = () => {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.date = date.value
  router.push({path: route.path, query: routeQuery})
}
const getReport = () => {
  fetchSalesReportProductWise(getQuery()).then(({status, ...rest}) => {
    if (!status) {
      lists.value = []
      return groupNames.value = {}
    }

    lists.value = rest.data
    groupNames.value = rest.group_names
    finalTotal.value = rest.final_total
    saleReturns.value = rest.sale_returns
    saleReturnPreviousBills.value = rest.sale_return_previous_bills

    ipdSettlementReceipts.value = rest.ipd_settlement_receipts
    previousBillReceipts.value = rest.previous_bill_receipts
    advanceReceipts.value = rest.advance_receipts
  }).catch((err) => {
    loading.value = false
  }).finally(() => {
    loading.value = false
  })
};

const getInvoiceType = () => {
  fetchHome().then(res => {
    if (res.data) {
      for (const value of Object.keys(res.data.hospital_invoice_types)) {
        billTypeList.value.push({
            value: value,
            label: res.data.hospital_invoice_types[value]
        })
      }
    }
  }).catch(e => showError('Something went wrong'))
}

const getContacts = () => {
  fetchContacts('?company_id=' + companyId.value).then(res => {
    if (res.data) {
      contactList.value = res.data;
    }
  }).catch(e => showError('Something went wrong'))
}

const exportReport = () => {
  let query = getQuery();
  let url = `${process.env.VUE_APP_BASE_URL}/export/hospital/sales-report-product-wise${query}&_token=${token}`;
  let a = document.createElement('a');
  a.setAttribute('href', url);
  a.click();
}

onMounted(async () => {
  date.value = route.query.date
  getInvoiceType()
  getContacts()
})
</script>
